import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../slices/authSlice';
import { fetchMCQList } from '../slices/studentSlice';

const MCQDetails = () => {
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [marksObtained, setMarksObtained] = useState(null);
  const [grade, setGrade] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const selectedTopicId = sessionStorage.getItem('selectedTopicId');
    const selectedCourseId = sessionStorage.getItem('selectedCourseId');

    if (!selectedTopicId || !selectedCourseId) {
      navigate('/'); // Redirect if course ID or topic ID is not found in sessionStorage
      return;
    }

    console.log('selectedCourseId', selectedCourseId);
    console.log('selectedTopicId', selectedTopicId);

    dispatch(fetchMCQList({ course_id: selectedCourseId, topic_id: selectedTopicId }))
      .unwrap()
      .then(response => {
        setQuestions(response.MCQList);
      })
      .catch(error => {
        console.error('Failed to fetch questions:', error);
      });
  }, [dispatch, navigate]);

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      localStorage.removeItem('token');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleAnswerChange = (questionId, answerId) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: answerId
    });
  };

  const handleSubmit = () => {
    let correctAnswers = 0;
    questions.forEach(question => {
      const correctAnswer = question.answers.find(answer => answer.is_correct);
      if (selectedAnswers[question.id] === correctAnswer.id) {
        correctAnswers++;
      }
    });
    const totalQuestions = questions.length;
    const percentage = (correctAnswers / totalQuestions) * 100;

    let grade = '';
    if (percentage < 33) {
      grade = 'Failed';
    } else if (percentage < 50) {
      grade = 'Pass';
    } else if (percentage < 60) {
      grade = 'Good';
    } else if (percentage < 80) {
      grade = 'Very Good';
    } else {
      grade = 'Excellent';
    }

    setMarksObtained(correctAnswers);
    setGrade(grade);
  };

  return (
    <div className="page-wrapper bg-gra-03 p-t-45 p-b-50">
      <div className="card">
        <div className="card-header">
          <div className='col-md-12 text-right'>
            <b>Welcome to Student's Dashboard</b>
            | <button onClick={handleLogout}><b>Logout <i className="fa fa-sign-out" aria-hidden="true"></i></b></button>
          </div>
        </div>
        <div className="card-body">
          <h2>Questions</h2>
          {questions.length > 0 ? (
            <ul className='dashboard_course_list' style={{ listStyle: 'none' }}>
              {questions.map((question) => (
                <li key={question.id} style={{
                  paddingTop: '10px',
                  borderBottom: '2px dotted gray',
                  backgroundColor: '#f2f2f2',
                  marginBottom: '15px',
                  padding: '15px'
                }}>
                  <h3 style={{ paddingBottom: '20px', color: 'blue', fontWeight: 'Bold' }}>
                    &nbsp;Q {question.question}
                  </h3>
                  {question.answers.map((answer) => (
                    <ul key={answer.id} style={{ listStyle: 'none', marginLeft: '20px' }}>
                      <li>
                        <input
                          type="radio"
                          name={`question-${question.id}`}
                          value={answer.id}
                          onChange={() => handleAnswerChange(question.id, answer.id)}
                          style={{ width: 'auto' }}
                        />
                        <span style={{ fontSize: '20px', color: 'rgb(128, 128, 128)' }}>
                          &nbsp;{answer.answer_text}
                        </span>
                      </li>
                    </ul>
                  ))}
                </li>
              ))}
            </ul>
          ) : (
            <p>Loading questions...</p>
          )}
          <button onClick={handleSubmit} className="btn btn-primary">Submit</button>
          {marksObtained !== null && (
            <div>
              <p>You have obtained {marksObtained} out of {questions.length} marks.</p>
              <p>Your grade: {grade}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MCQDetails;

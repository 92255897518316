import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { validateEmail,changePassword } from '../slices/authSlice';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [reset_code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [messages, setMessages] = useState('');
  const [showFirstForm, setShowFirstForm] = useState(true); // State to control the visibility of the first form
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleValidatePassword = async () => {
    try {
      if (!email) {
        setMessages('Email id is required');
        return;
      }

      // Dispatch validateEmail action with email
      const result = await dispatch(validateEmail(email));

      if (result.payload && result.payload.message === 'Code sent successfully') {
        setShowFirstForm(false); // Hide the first form
        setMessages(''); // Clear any error messages
      } else {
        setMessages('Invalid email');
      }
    } catch (error) {
      // Set error message if validation fails
      setMessages('Failed to validate email');
    }
  };

  const handleChangePassword = async () => {
    if (!password || !retypePassword) {
      setMessages('Password and retype password are required');
      return;
    }

    if (password !== retypePassword) {
      setMessages('Passwords do not match');
      return;
    }

    if (!email || !reset_code) {
      setMessages('Email and code are required');
      return;
    }

    try {
      const result = await dispatch(changePassword({ email, reset_code, password }));
      console.log('result.payload',result.payload);
      if (result.payload && result.payload == 'Password changed successfully') {
        navigate('/login'); // Redirect to login page after successful password change
      } else {
        setMessages(result.payload);
      }
    } catch (error) {
      setMessages('Failed to change password');
    }
  };

  return (
    <>
      {showFirstForm && (
        <div className="page-wrapper bg-gra-03 p-t-25 p-b-50" style={{ width: '', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto' }}>
          <div className="card" style={{ width: '400px' }}>
            {messages ? (
              <div className="card-header error text-center" style={{ backgroundColor: 'darkblue',color:'white' }}><h3>{messages}</h3></div>
            ) : (
              <div className="card-header text-center" style={{ backgroundColor: 'darkblue', color: 'white' }}><h3><i className="fa fa-graduation-cap"></i>&nbsp;Password Recovery</h3></div>
            )}
            <div className="card-body">
              <div className="form-group custom-form-group">
                <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className='col-md-12 text-center custom-form-group'>
                <button className="btn btn-success btn-block login-btn" onClick={handleValidatePassword} style={{ backgroundColor: 'darkblue' }}>Validate Email</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!showFirstForm && (
        <div className="page-wrapper bg-gra-03 p-t-145 p-b-50" style={{ width: '', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto' }}>
          <div className="card" style={{ width: '400px' }}>
            {messages ? (
              <div className="card-header error" style={{ backgroundColor: 'darkblue' }}><h3>{messages}</h3></div>
            ) : (
              <div className="card-header text-center" style={{ backgroundColor: 'darkblue', color: 'white' }}><h3><i className="fa fa-graduation-cap"></i>&nbsp;Change Password</h3></div>
            )}
            <div className="card-body">
              <div className="form-group custom-form-group">
                <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="form-group custom-form-group">
                <input type="number" className="form-control" placeholder="Enter Code" value={reset_code} onChange={(e) => setCode(e.target.value)} />
              </div>
              <div className="form-group custom-form-group">
                <input type="password" className="form-control" placeholder="New Password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="form-group custom-form-group">
                <input type="password" className="form-control" placeholder="Re-Type Password" value={retypePassword} onChange={(e) => setRetypePassword(e.target.value)} />
              </div>
              <div className='col-md-12 text-center custom-form-group'>
                <button className="btn btn-success btn-block login-btn" onClick={handleChangePassword} style={{ backgroundColor: 'darkblue' }}>Change Password</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ForgotPassword;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const mainURL="https://www.acesoftech.com/addmission/backend";
const initialState = {
  isLoggedIn: false,
  isLoading: false,
  user: null,
  kycData: null,
  courseDetails: null,
  error: null,
};



export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({ email, reset_code, password }, thunkAPI) => {
    try {
      const response = await axios.post(`${mainURL}/api/change-password`, { email, reset_code, password });
      return response.data.message; // Assuming the API returns a message upon successful password change
    } catch (error) {
      throw new Error('Failed to change password');
    }
  }
);


export const validateEmail = createAsyncThunk(
  'auth/validateEmail',
  async (email, thunkAPI) => {
    try {
      const response = await axios.post(`${mainURL}/api/validate-email/validateEmailAndSendCode`, { email });
      // Assuming the API returns a boolean value
     //console.log('response',response);
      return response.data;
    } catch (error) {
      throw new Error('Failed to validate email');
    }
  }
);


export const fetchCourseDetails = createAsyncThunk('auth/fetchCourseDetails', async (_, thunkAPI) => {
  const tokenString = localStorage.getItem('token');
  if (tokenString) {
    try {
      const tokenObject = JSON.parse(tokenString);
      const id = tokenObject.id;
      const response = await axios.get(mainURL+`/api/admin/course_topics/getCourseDetailsByStudentId/${id}`);
    
    //console.log('response',response);

      const courseDetails = response.data.courses;
      return courseDetails;
    } catch (error) {
      throw new Error('Failed to fetch course details');
    }
  } else {
    throw new Error('Token not found in localStorage');
  }
});


export const fetchStudentKyc = createAsyncThunk('auth/fetchStudentKyc', async (_, thunkAPI) => {
  const tokenString = localStorage.getItem('token');
  if (tokenString) {
    try {
      const tokenObject = JSON.parse(tokenString);
      const id = tokenObject.id;
      const response = await axios.get(`${mainURL}/api/studentskyc/${id}`);
    
      const kycData = response.data; // Assuming KYC data is returned directly
      
      return kycData;
    } catch (error) {
      throw new Error('Failed to fetch KYC details');
    }
  } else {
    throw new Error('Token not found in localStorage');
  }
});



export const updateKyc = createAsyncThunk(
  'auth/updateKyc',
  async ({ id, formData }, thunkAPI) => {
    try {
      const response = await axios.post(`${mainURL}/api/updateKyc/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to update KYC');
    }
  }
);



export const login = createAsyncThunk('auth/login', async ({ credentials }, { rejectWithValue }) => {
  const { email, password } = credentials;
  try {
    const response = await axios.post(mainURL+'/api/students/login', { email, password });
    if (response.data.message === 'Login Successful') {
      localStorage.setItem('token', JSON.stringify(response.data.student));
      return response.data.student;
    } else {
      throw new Error("Invalid credentials");
    }
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'An error occurred');
  }
});

export const loadToken = () => {
  const tokenString = localStorage.getItem('token');
  if (tokenString) {
    try {
      JSON.parse(tokenString);
    } catch (error) {
      localStorage.setItem('token', JSON.stringify(tokenString));
    }
    return tokenString;
  } else {
    return null;
  }
};

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    const response = await axios.post(mainURL+'/api/students/logout');
    return response.data.message;
  } catch (error) {
    throw new Error('Logout failed');
  }
});

export const fetchCurrentUser = createAsyncThunk('auth/fetchCurrentUser', async (_, thunkAPI) => {
  const tokenString = localStorage.getItem('token');
  if (tokenString) {
    try {
      const tokenObject = JSON.parse(tokenString);
      const id = tokenObject.id;
     // console.log('id',id);
      const response = await axios.get(mainURL+`/api/students/${id}`);
      const user = response.data.student;
  //console.log('DDDD',response.data.student)
      // Dispatch actions to set first name and last name
      // thunkAPI.dispatch(setFirstName(user.first_name));
      // thunkAPI.dispatch(setLastName(user.last_name));

      return user;
    } catch (error) {
      throw new Error('Failed to fetch current user');
    }
  } else {
    throw new Error('Token not found in localStorage');
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setFirstName: (state, action) => {
      state.user.first_name = action.payload;
    },
    setLastName: (state, action) => {
      state.user.last_name = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.error = null;
        state.user = action.payload; // Set user data after successful login
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.error = null;
        state.user = null; // Reset user data after logout
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCurrentUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCourseDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCourseDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.courseDetails = action.payload; // Set course details after successful fetch
      })
      .addCase(fetchCourseDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // other cases...
      .addCase(fetchStudentKyc.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchStudentKyc.fulfilled, (state, action) => {
        state.isLoading = false;
        state.kycData = action.payload; // Set KYC data after successful fetch
      })
      .addCase(fetchStudentKyc.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateKyc.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateKyc.fulfilled, (state) => {
        state.isLoading = false;
        // Handle success if needed
      })
      .addCase(updateKyc.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(validateEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(validateEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        // Set the email validation result in the state
        state.emailIsValid = action.payload;
      })
      .addCase(validateEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(changePassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        // Handle success if needed
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectIsLoggedIn = state => state.auth.isLoggedIn;
export const selectCurrentUser = state => state.auth.user;

export default authSlice.reducer;

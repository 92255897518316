import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { logout } from '../slices/authSlice';
import { fetchInterviewList } from '../slices/studentSlice';

const InterviewDetails = () => {
  const [topics, setTopics] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    const selectedTopicId = sessionStorage.getItem('selectedTopicId');
    const selectedCourseId = sessionStorage.getItem('selectedCourseId');

    if (!selectedTopicId || !selectedCourseId) {
      navigate('/'); // Redirect if course ID or topic ID is not found in sessionStorage
      return;
    }

    console.log('selectedCourseId', selectedCourseId);
    console.log('selectedTopicId', selectedTopicId);

    dispatch(fetchInterviewList({ course_id: selectedCourseId, topic_id: selectedTopicId }))
      .unwrap()
      .then(topics => {
        setTopics(topics);
      })
      .catch(error => {
        console.error('Failed to fetch topics:', error);
      });
  }, [dispatch, navigate]);

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      localStorage.removeItem('token');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleTopicClick = (topicId) => {
    localStorage.setItem('selectedTopicId', topicId);
    navigate('/interview-details');
  };

  return (
    <div className="page-wrapper bg-gra-03 p-t-45 p-b-50">
      <div className="card">
        <div className="card-header">
          <div className='col-md-12 text-right'>
            <b>Welcome to Student's Dashboard</b>
            | <button onClick={handleLogout}><b>Logout <i className="fa fa-sign-out" aria-hidden="true"></i></b></button>
          </div>
        </div>
        <div className="card-body">
          <h2>Topics</h2>
          {topics.length > 0 ? (
            <ul className='dasboard_course_list' style={{listStyle:'none'}}>
              {topics.map((questionList, index) => (
                <li key={questionList.id} style={{paddingTop:'10px', 
                borderBottom:'2px dotted gray',
                backgroundColor: 'rgb(234, 250, 255)',marginBottom:'15px',
                padding:'15px'}}>
                
                <h3 style={{paddingBottom:'20px'}}>&nbsp;Q{index + 1}:&nbsp;{questionList.question}</h3>
                <p>A:&nbsp;{questionList.answer}</p>
                 
                </li>
              ))}
            </ul>
          ) : (
            <p>Loading topics...</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default InterviewDetails;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { logout } from '../../slices/authSlice';

const Header = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [status, setStatus] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Get the navigate function

  useEffect(() => {
    // Retrieve token from local storage
    const token = localStorage.getItem('token');
    if (token) {
      try {
        // Parse the token string into an object
        const tokenObject = JSON.parse(token);

        // Set the first name and last name
        setFirstName(tokenObject.first_name);
        setLastName(tokenObject.last_name);
        setStatus(tokenObject.status);
      } catch (error) {
        console.error('Error parsing token:', error);
      }
    }
  }, []);


  //console.log('Status',status)

  const handleLogout = async () => {
    try {
      // Dispatch the logout action
      await dispatch(logout());
      // Clear local storage
      localStorage.removeItem('token');
      // Redirect to login page using navigate function
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <header>
      <div className="container-fluid header">
        <nav className="navbar navbar-expand-lg container">
          <Link to="/student-dashboard" className="navbar-brand">
            <img src="/assets/images/logo.png" alt="logo" style={{width:'80%'}} />
          </Link>
          <button className="navbar-toggler" style={{backgroundColor:'white',float:'right'}}
           type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" style={{color:'white'}} ></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {localStorage.getItem('token') && (
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" id="nav_dropdown"
                   href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false" style={{color:'white'}}>
                   Useful Links
                  </a>
                  <ul className="dropdown-menu">
                   
                    
                  {status == 0 ? (
                <li>Your Account is not active. Contact Admin</li>
            ) : (
                <>
                    <li><a className="dropdown-item" href="#">Career Guide</a></li>
                    <li><a className="dropdown-item" href="#">Videos</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="#">Interview QA</a></li>
                    <li><Link className="dropdown-item" to="/resume-building">Resume Building</Link></li>
                </>
            )}
                 

                 
                 
                  </ul>
                </li>
              )}
            </ul>
            <style>
                {`
                .contact_numbers {
                    color: white;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }

                .contact_numbers a {
                    color: white !important;
                    text-decoration: none;
                }

                .contact_numbers a:hover {
                    color: blue;
                }
                `}
            </style>
            <div className="d-flex">
              <div className="row" >
                <div className="col-md-12 contact_numbers">
                  <a href="tel:+91 85839595284"><i className="fa fa-whatsapp" aria-hidden="true"></i>&nbsp;8583959528</a> &nbsp;
                  <a href="tel:+91 85839595284"><i className="fa fa-mobile" aria-hidden="true"></i>&nbsp;9875642702</a>
                </div>

                <div className="col-md-12 users_info">
  {localStorage.getItem('token') && (
    <>
      <b style={{ color: 'orange' }}>Welcome!  {firstName} {lastName}</b>
      | <button style={{ color: 'orange' }} onClick={handleLogout}><b>Logout <i className="fa fa-sign-out" aria-hidden="true"></i></b></button> |
      <span>
        {status == 0 ? (
          <>
            <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>
            &nbsp;&nbsp;<span style={{ color: 'red' }}>Inactive</span>
          </>
        ) : (
          <>
            <i className="fa fa-check-circle text-success" aria-hidden="true"></i>
            &nbsp; &nbsp; <span style={{ color: 'green' }}>Active</span>
          </>
        )}
      </span>
    </>
  )}
</div>


              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;

// StudentDetails.js

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentUser, selectCurrentUser } from '../slices/authSlice';
import { selectImagePath } from '../slices/globalSlice';
const StudentDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const imagePath = useSelector(selectImagePath)
  console.log('userssss',user);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  
  }, [dispatch]);

  return (
    <div>
      <h2>Student Details</h2>
      {user && (
        <table className="table">
          <tbody>
            <tr>
              <td>First Name</td>
              <td>{user.first_name}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{user.last_name}</td>
            </tr>

            <tr>
              <td>ID Proof</td>
              <td>
              {user.id_proof}
              
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{user.email}</td>
            </tr>
            <tr>
              <td>Mobile No</td>
              <td>{user.mobile_no}</td>
            </tr>
            <tr>
              <td>Date of Birth</td>
              <td>{user.dob}</td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>{user.gender}</td>
            </tr>
            <tr>
              <td>Area Code</td>
              <td>{user.area_code}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{user.address}</td>
            </tr>
            <tr>
              <td>Country</td>
              <td>{user.country}</td>
            </tr>
            <tr>
              <td>State</td>
              <td>{user.state}</td>
            </tr>
            <tr>
              <td>My Photo</td>
              <td>
              {imagePath && user.student_photo && (
                  <img src={`${imagePath}${user.student_photo}`} alt="Student" height={200} width={200} />
                )}
              </td>
            </tr>
            <tr>
              <td>Madh Marks</td>
              <td>{user.madh_marks}</td>
            </tr>
            <tr>
              <td>Madh Year</td>
              <td>{user.madh_year}</td>
            </tr>
            <tr>
              <td>Madh Board</td>
              <td>{user.madh_board}</td>
            </tr>
            <tr>
              <td>Higher Marks</td>
              <td>{user.higher_marks}</td>
            </tr>
            <tr>
              <td>Higher Year</td>
              <td>{user.higher_year}</td>
            </tr>
            <tr>
              <td>Higher Board</td>
              <td>{user.higher_board}</td>
            </tr>
            <tr>
              <td>Graduation Marks</td>
              <td>{user.graduation_marks}</td>
            </tr>
            <tr>
              <td>Graduation Year</td>
              <td>{user.graduation_year}</td>
            </tr>
            <tr>
              <td>Graduation Board</td>
              <td>{user.graduation_board}</td>
            </tr>
            <tr>
              <td>Post Marks</td>
              <td>{user.post_marks}</td>
            </tr>
            <tr>
              <td>Post Year</td>
              <td>{user.post_year}</td>
            </tr>
            <tr>
              <td>Post Board</td>
              <td>{user.post_board}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default StudentDetails;

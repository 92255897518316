import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate,Link } from 'react-router-dom';
import { fetchCourseList, submitForm,fetchCountries,fetchStates } from '../slices/studentSlice';

const RegistrationForm = () => {

  const [formData, setFormData] = useState({
    admission_date: "",
    form_no: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    courses_name: [],
    course_duration: "",
    mobile_no: "",
    dob: "",
    gender: "",
    area_code: "",
    address: "",
    country: "",
    state: "",
    madh_marks: "",
    madh_year: "",
    madh_board: "",
    higher_marks: "",
    higher_year: "",
    higher_board: "",
    graduation_marks: "",
    graduation_year: "",
    graduation_board: "",
    post_marks: "",
    post_year: "",
    post_board: "",
    student_photo: null // Initialize with null
  });

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      student_photo: file,
    });
  };

  const [errors, setErrors] = useState({});
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState('');
  const [submitting, setSubmitting] = useState(false); // State to track form submission
  const [errorMessage, setErrorMessage] = useState('');
  const courseList = useSelector((state) => state.studentForm.courseList);
  const countries = useSelector((state) => state.studentForm.countries);
  const states = useSelector((state) => state.studentForm.states);
  const formStatus = useSelector((state) => state.studentForm.status);
 //console.log('formStatus',formStatus);

  useEffect(() => {
    dispatch(fetchCourseList());
    dispatch(fetchCountries());
    dispatch(fetchStates());
  }, [dispatch]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'courses_name') {
      const options = e.target.options;
      const selectedCourses = [...formData.courses_name]; // Copy existing selected courses
  
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          const [courseId, courseName, duration] = options[i].value.split('_');
          const newCourse = {
            course_id: courseId,
            course_names: courseName,
            course_duration: duration
          };
          
          // Check if the course is not already selected
          if (!selectedCourses.find(course => course.course_id === newCourse.course_id)) {
            selectedCourses.push(newCourse); // Append the new course
          }
        }
      }
  
      setFormData({
        ...formData,
        courses_name: selectedCourses,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  
  
  const removeCourse = (courseId) => {
    setFormData(prevState => ({
      ...prevState,
      courses_name: prevState.courses_name.filter(course => course.course_id !== courseId)
    }));
  };
  
  const renderCourseOptions = (courses, level = 0) => {
    return courses.map((course) => (
      <React.Fragment key={course.id}>
        <option value={`${course.id}_${course.name}_${course.duration}`}>{'-'.repeat(level)} {course.name}</option>
        {course.children && renderCourseOptions(course.children, level + 1)}
      </React.Fragment>
    ));
  };
  
  


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true); // Set form submitting to true
    const mergedFormData = { ...formData }; // Create a copy of formData

    console.log('mergedFormData',mergedFormData);
    
    const validationErrors = validateForm(mergedFormData);

    console.log('validationErrors', validationErrors);

    
    if (Object.keys(validationErrors).length === 0) {
      dispatch(submitForm(formData))
        .unwrap()
        .then((result) => {
          // Handle success
          console.log('Form submitted successfully:', result);
          // Redirect to student dashboard after 2 seconds
          setTimeout(() => {
            localStorage.setItem('email', formData.email);
            // Redirect to student dashboard
             navigate('/student-dashboard');
          }, 2000);
        })
        .catch((error) => {
          // Handle error
          console.error('Form submission failed:', error);
          setErrorMessage(error.message);
          setSubmitting(false); // Set form submitting back to false in case of error
          // if (error.message === 'The email has already been taken.') {
          //   setErrorMessage(error.message);
          // } else {
          //   setErrorMessage('Something went wrong');
          // }
        });
    } else {
      setErrors(validationErrors);
      setSubmitting(false); // Set form submitting back to false in case of error
    }


  };

  const validateForm = (data) => {
    let errors = {};
    // Validate fields here
     //alert(data.student_photo);

    // console.log(errors.student_photo); // This should work now
    // if (!data.admission_date) {
    //   errors.admission_date = 'Admission date is required';
    // }
    
     if(data.student_photo === null) {
      errors.student_photo = "Your Photo is required";
      console.log(errors.student_photo); // This should work now
    }

    if (!data.first_name) {
      errors.first_name = 'First name is required';
    }
    if (!data.last_name) {
      errors.last_name = 'Last name is required';
    }


    if (!data.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Enter a valid email address';
    }
    
    // Validate password
    if (!data.password) {
      errors.password = 'Password is required';
    } else if (data.password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    }
    if (!data.courses_name || data.courses_name.length === 0) {
      errors.courses_name = 'At least one course must be selected';
    }
   
    if (!data.mobile_no) {
      errors.mobile_no = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(data.mobile_no)) {
      errors.mobile_no = 'Invalid mobile number';
    }
    if (!data.dob) {
      errors.dob = 'Date of birth is required';
    }
    if (!data.gender) {
      errors.gender = 'Gender is required';
    }
    if (!data.area_code) {
      errors.area_code = 'Area code is required';
    }
    if (!data.address) {
      errors.address = 'Address is required';
    }
    // if (!data.state) {
    //   errors.state = 'State is required';
    // }
    if (!data.madh_marks) {
      errors.madh_marks = 'Madhyamik marks are required';
    }
    if (!data.madh_year) {
      errors.madh_year = 'Madhyamik year is required';
    }
    if (!data.madh_board) {
      errors.madh_board = 'Madhyamik board/university is required';
    }

    if (!data.courses_name || data.courses_name.length === 0) {
      errors.courses_name = 'At least one course must be selected';
    }
  

    // Validate image field
   
    return errors;
  };

//console.log(courseList);




  return (
    <>
      <div className="page-wrapper bg-gra-03 p-t-145 p-b-50"  style={{'paddingTop':'30px'}}>
        <div className="wrapper wrapper--w790">
          <div className="card card-5" style={{ borderRadius: '12px' }}>
            <div className="card-heading">
              <h2 className="title">
              <i class="fa fa-graduation-cap"></i>&nbsp;
              STUDENT'S ADMISSION FORM</h2>
            </div>
           
            <div className="card-body">
           <div className='col-md-12 text-center'>
           {formStatus === 'fulfilled' && !errorMessage && (
              <h3 className="success-message">Form Submitted Successfully!</h3>
              )}

              {errorMessage && (
                  <h3 className="error-message">{errorMessage}</h3>
              )}
           </div>          
              <form method="POST" enctype="multipart/form-data" onSubmit={handleSubmit}>
                <div className="container">
                
                  <div className="form-row m-b-55">
                    <div className="name">Name*</div>
                    <div className="value">
                      <div className="row row-space">
                        <div className="col-6">
                          <div className="input-group-desc">
                          <div class="textbox-container">
                             <i class="fa fa-user-circle-o"></i>
                            <input className="input--style-5" type="text" name="first_name" 
                             value={formData.first_name} onChange={handleChange} />
                         
                            
                            <label className="label--desc">first name</label>
                            {errors.first_name && <p className="error">{errors.first_name}</p>}
                           
                            </div>

                          </div>
                        </div>
                        <div className="col-6">
                          <div className="input-group-desc">
                          <div class="textbox-container">
                             <i class="fa fa-user-circle-o"></i>
                            <input className="input--style-5" type="text" name="last_name"  value={formData.last_name} onChange={handleChange} />
                            <label className="label--desc">Last Name</label>
                            {errors.last_name && <p className="error">{errors.last_name}</p>}
                         </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row m-b-55">
                    <div className="name">Email*</div>
                    <div className="value">
                      <div className="row row-space">
                        <div className="col-6">
                          <div className="input-group-desc">
                          <div class="textbox-container">
                             <i class="fa fa-envelope"></i>
                            <input className="input--style-5 emails" type="text" name="email"  value={formData.email} onChange={handleChange} />
                            
                            <label className="label--desc"> Email Id:</label>
                            {errors.email && <p className="error">{errors.email}</p>}
                         </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="input-group-desc">
                          <div class="textbox-container">
                             <i class="fa fa-key"></i>
                            <input className="input--style-5" type="password" name="password"  value={formData.password} onChange={handleChange} />
                           
                            <label className="label--desc">Password </label>
                            {errors.password && <p className="error">{errors.password}</p>}
                         </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row m-b-55">
                  <div className="col-md-6">
                    <b>Upload Photo:</b> 
                  </div>
                  <div className="col-md-6">
                      <div className="form-group">
                      <div class="textbox-container">
                             <i class="fa fa-cloud-upload"></i>
                      
                        <input type="file" className="form-control" name="student_photo" onChange={handlePhotoChange} />
                     
                        {errors.student_photo && <p className="error">{errors.student_photo}</p>}
                    </div>
                      </div>
                    </div>
                </div>

                  <div className="form-row m-b-55">
                    <div className="name">Course Name*</div>
                    <div className="value">
                    <div className="row row-space">
  <div className="col-md-6">
    <div className="input-group-desc">
      <i className="fa fa-book"></i>
      <select
        name="courses_name"
        className="form-control courses_list"
        value={formData.courses_name.length > 0 ? `${formData.courses_name[0].course_id}_${formData.courses_name[0].course_names}_${formData.courses_name[0].course_duration}` : "0"}
        onChange={handleChange}
      >
        <option value="0">Select Course</option>
        {renderCourseOptions(courseList)}
      </select>
    </div>
    {errors.courses_name && <p className="error">{errors.courses_name}</p>} {/* Display error message */}
  </div>
  <div className='col-md-6'>
    <h3 style={{paddingTop:'15px'}}>Selected Courses</h3>
    <ul style={{listStyle:'none'}}>
      {formData.courses_name.map(course => (
        <li key={course.course_id}>
          <b><i className="fa fa-book"></i>&nbsp; {course.course_names}</b> - 
          <span><b>Duration:</b> {course.course_duration} Months 
            <button onClick={() => removeCourse(course.course_id)}>
              &nbsp;<i className="fa fa-trash" style={{color:'red'}}></i>
            </button>
          </span>
        </li>
      ))}
    </ul>
  </div>
</div>


                    </div>
                  </div>
              
      <div className="form-row">
        <div className="name">Mobile No:*</div>
        <div className="value">
          <div className="input-group col-6">
          <div class="textbox-container">
           <i class="fa fa-mobile"></i>
            <input className="input--style-5" type="number" name="mobile_no" value={formData.mobile_no} onChange={handleChange} />
            {errors.mobile_no && <p className="error">{errors.mobile_no}</p>}
         </div>
          </div>
        </div>
</div>
<div className="form-row">
  <div className="name">Date of Birth*</div>
  <div className="value">
    <div className={`input-group col-sm-12 ${errors.dob ? 'is-invalid' : ''}`}>
      <input 
        className="input--style-5"
        type="date"
        name="dob"
        value={formData.dob}
        onChange={handleChange}
      />
    </div>
    {errors.dob && (
      <div className="error">{errors.dob}</div>
    )}
  </div>
</div>
<div className="form-row m-b-55">
  <div className="name">Gender*</div>
  
    
  <ul style={{ listStyleType: 'none', padding: 0 }}>
  <li style={{ float: 'left', marginRight: '10px' }}>
    <input
      type="radio"
      id="customRadioInline1"
      name="gender"
      className="custom-control-input"
      value="male"
      onChange={handleChange}
    />
    <label htmlFor="customRadioInline1"><i className=' 	fa fa-male'></i>&nbsp;Male</label>
  </li>
  <li style={{ float: 'left' }}>
    <input
      type="radio"
      id="customRadioInline2"
      name="gender"
      className="custom-control-input"
      value="female"
      onChange={handleChange}
    />
    <label htmlFor="customRadioInline2"><i className=' 	fa fa-female'></i> Female</label>
  </li>
</ul>

   
   


  {errors.gender && <p className="error">{errors.gender}</p>}

</div>
<div className="form-row m-b-55">
  <div className="name">Address</div>
  <div className="value">
    <div className="row row-refine">
      <div className="col-3">
        <div className="input-group-desc">
        <div class="textbox-container">
    <i class=" fa fa-address-card-o"></i>
       
          <input className="input--style-5" type="text" name="area_code" value={formData.area_code} onChange={handleChange} />
          <label className="label--desc">Area Code</label>
     </div>   
        </div>
        {errors.area_code && <p className="error">{errors.area_code}</p>}
      </div>
      <div className="col-9">
        <div className="input-group-desc">
        <div class="textbox-container">
          <i class="fa fa-home"></i>
       
          <input className="input--style-5" type="text" name="address" value={formData.address} onChange={handleChange} />
          <label className="label--desc">Home Address</label>
          {errors.address && <p className="error">{errors.address}</p>}
       </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div className="form-row">


  <div className="name">Country*</div>
  <div className="value">
    <div className="input-group col-6">
      <div className="rs-select2 js-select-simple select--no-search">
      <div class="textbox-container" style={{'paddingRight':'-30px'}}> 
          <i class="fa fa-vcard"></i>
      
      <select name="country" className="form-control" value={formData.country}
       onChange={handleChange} style={{paddingLeft:'40px'}}>
                            <option value="">SELECT COUNTRY</option>
                            {countries.map((country) => (
                              <option key={country.country_name} value={country.country_name}>
                                {country.country_name}
                              </option>
                            ))}
                          </select>
                          <div className="select-dropdown"></div>
                          {errors.country && <p className="error">{errors.country}</p>}
     </div>
      </div>
    </div>
  </div>
</div>


<div className="form-row" style={{ display: formData.country === 'India' ? 'block' : 'none' }}>
 
<div className='col-md-4'>State*</div>
<div className='col-md-8'>

  <select name="state" className="form-control" value={formData.state} onChange={handleChange}>
          <option value="">SELECT STATE</option>
          {states.map((state) => (
            <option key={state.id} value={state.id}>
              {state.state}
            </option>
          ))}
        </select>

</div>
 
 
  <div className="name">State*</div>
  <div className="value">
 <div className='col-md-2'></div>

    <div className="input-group col-6">
      <div className="rs-select2 js-select-simple select--no-search">
      <div class="textbox-container" > 
        
      
        
                          <div className="select-dropdown"></div>
                          
     </div>
      </div>
    </div>

  </div>
</div>



<div className="form-row m-b-55">
  <div className="name">Qualification*/<br />Madhyamik</div>
  <div className="value">
    <div className="row row-refine">
      <div className="col-4">
        <div className="input-group-desc">
        <div class="textbox-container">
        <i class="fa fa-graduation-cap"></i>
        
          <input className="input--style-5" type="text" name="madh_marks" value={formData.madh_marks} onChange={handleChange} />
          <label className="label--desc"> % of Marks</label>
          {errors.madh_marks && <p className="error">{errors.madh_marks}</p>}
       </div>
        </div>
      </div>
      <div className="col-4">
        <div className="input-group-desc">
        <div class="textbox-container">
        <i class="fa fa-graduation-cap"></i>
        
          <input className="input--style-5" type="text" name="madh_year" value={formData.madh_year} onChange={handleChange} />
          <label className="label--desc">Year</label>
          {errors.madh_year && <p className="error">{errors.madh_year}</p>}    
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="input-group-desc">
        <div class="textbox-container">
        <i class="fa fa-graduation-cap"></i>
          <input className="input--style-5" type="text" name="madh_board" value={formData.madh_board} onChange={handleChange} />
          <label className="label--desc"> Board/University</label>
          {errors.madh_board && <p className="error">{errors.madh_board}</p>} 
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* Other qualification fields */}
<div className="form-row m-b-55">
  <div className="name">Qualification*/<br />Higher Secondary(H.S)</div>
  <div className="value">
    <div className="row row-refine">
      <div className="col-4">
        <div className="input-group-desc">
        <div class="textbox-container">
        <i class="fa fa-graduation-cap"></i>
          <input className="input--style-5" type="text" name="higher_marks" value={formData.higher_marks} onChange={handleChange} />
          <label className="label--desc"> % of Marks</label>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="input-group-desc">
        <div class="textbox-container">
        <i class="fa fa-graduation-cap"></i>
          <input className="input--style-5" type="text" name="higher_year" value={formData.higher_year} onChange={handleChange} />
          <label className="label--desc">Year</label>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="input-group-desc">
        <div class="textbox-container">
        <i class="fa fa-graduation-cap"></i>
          <input className="input--style-5" type="text" name="higher_board" value={formData.higher_board} onChange={handleChange} />
          <label className="label--desc"> Board/University</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="form-row m-b-55">
  <div className="name">Qualification*/<br />Graduation</div>
  <div className="value">
    <div className="row row-refine">
      <div className="col-4">
        <div className="input-group-desc">
        <div class="textbox-container">
        <i class="fa fa-graduation-cap"></i>
          <input className="input--style-5" type="text" name="graduation_marks" value={formData.graduation_marks} onChange={handleChange} />
          <label className="label--desc"> % of Marks</label>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="input-group-desc">
        <div class="textbox-container">
        <i class="fa fa-graduation-cap"></i>
          <input className="input--style-5" type="text" name="graduation_year" value={formData.graduation_year} onChange={handleChange} />
          <label className="label--desc">Year</label>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="input-group-desc">
        <div class="textbox-container">
        <i class="fa fa-graduation-cap"></i>
          <input className="input--style-5" type="text" name="graduation_board" value={formData.graduation_board} onChange={handleChange} />
          <label className="label--desc"> Board/University</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="form-row m-b-55">
  <div className="name">Qualification*/<br />Post Graduation</div>
  <div className="value">
    <div className="row row-refine">
      <div className="col-4">
        <div className="input-group-desc">
        <div class="textbox-container">
        <i class="fa fa-graduation-cap"></i>
          <input className="input--style-5" type="text" name="post_marks" value={formData.post_marks} onChange={handleChange} />
          <label className="label--desc"> % of Marks</label>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="input-group-desc">
        <div class="textbox-container">
        <i class="fa fa-graduation-cap"></i>
          <input className="input--style-5" type="text" name="post_year" value={formData.post_year} onChange={handleChange} />
          <label className="label--desc">Year</label>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="input-group-desc">
        <div class="textbox-container">
        <i class="fa fa-graduation-cap"></i>
          <input className="input--style-5" type="text" name="post_board" value={formData.post_board} onChange={handleChange} />
          <label className="label--desc"> Board/University</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


                </div>
                <div className="form-group">
                <button className="btn btn--radius-2 btn--red w-100" type="submit" disabled={submitting} // Disable the button when submitting
                      style={{ 'background': '#02004e', 'color': 'white' }}>
                      {submitting ? "Please Wait..." : "Enroll Now"} {/* Change button text based on form submitting state */}
                      <i className="fa fa-forward" style={{ marginLeft: "5px" }}></i>
              </button>

<div className='col-md-12 text-center'>
{formStatus === 'fulfilled' && !errorMessage && (
              <h3 className="success-message">Form Submitted Successfully! Redirecting to Login page</h3>
              )}
   {errorMessage && (
 <h3 className="error-message">{errorMessage}</h3>
)}
</div>

<div className='col-md-12 text-center p-b-40'>
<br/>
  Are you existing student? <Link to='/login'>Login Here</Link>
</div>
<div className='col-md-12 text-center p-b-40'>
<br/>
  This form is powered with Laravel as back-end API and React Js with ReduxToolkit as front-end.

</div>


                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationForm;

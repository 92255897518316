import React from 'react';

const Footer = () => {
    return (
        <>
            <div className="container-fluid" style={{ backgroundColor: 'black', color: 'white',
            minHeight:'50px' }}>
                <div className="row">
                   
                <div className="col-md-12 text-center">
                        &copy; Acesoftech Academy
                    </div>
                    <div className="col-md-12 text-center">
                        Developed using Laravel API and React ReduxToolkit
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default Footer;

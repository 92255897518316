import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import { logout, fetchStudentKyc, updateKyc } from '../slices/authSlice';

const Kyc = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [studentId, setStudentId] = useState('');
  const [status, setStatus] = useState('');
  const [kycData, setKycData] = useState(null);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  const isLoading = useSelector(state => state.auth.isLoading);
  const error = useSelector(state => state.auth.error);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const tokenObject = JSON.parse(token);
        setFirstName(tokenObject.first_name);
        setLastName(tokenObject.last_name);
        setStatus(tokenObject.status);
        setStudentId(tokenObject.id);

        dispatch(fetchStudentKyc())
          .unwrap()
          .then(kyc => {
            setKycData(kyc);
          })
          .catch(error => {
            console.error('Failed to fetch KYC details:', error);
          });
      } catch (error) {
        console.error('Error parsing token:', error);
      }
    }
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      localStorage.removeItem('token');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file');
      return;
    }

    const formData = new FormData();
    formData.append('kyc', file); // Corrected file name to 'kyc'

    try {
      await dispatch(updateKyc({ id: studentId, formData }));
      // Refresh KYC data after update
      dispatch(fetchStudentKyc())
        .unwrap()
        .then(kyc => {
          setKycData(kyc);
        })
        .catch(error => {
          console.error('Failed to fetch KYC details:', error);
        });
    } catch (error) {
      console.error('KYC update failed:', error);
    }
  };

  return (
    <div className="page-wrapper bg-gra-03 p-t-45 p-b-50">
      <div className="card">
        <div className="card-header">
          <div className='col-md-12 text-right'>
            <b>Welcome to Student's Dashboard</b>
            | <button onClick={handleLogout}><b>Logout <i className="fa fa-sign-out" aria-hidden="true"></i></b></button>
          </div>
        </div>
        <div className="card-body">
           <div className='row'>
            <div className='col-md-6'>
               Upload KYC:
            </div>
            <div className='col-md-6'>
               <input type='file' accept='image' name='kyc' onChange={handleFileChange} />
               <button onClick={handleUpload} className='btn btn-success'
               style={{marginTop:'10px'}}>Upload</button>
            </div>
           </div>
           {isLoading && <div>Loading KYC data...</div>}
           {error && <div>Error: {error}</div>}
           {!isLoading && kycData && kycData.student.kyc && (
             <div>
               <h2>Your KYC is uploaded:</h2>
                <img  style={{height:'200','width':'200'}} src={`https://www.acesoftech.com/addmission/backend/student_photos/${kycData.student.kyc}`} alt="KYC" />
             </div>
           )}
           {!isLoading && kycData && kycData.student.kyc === "" && (
             <div><h2>Your KYC is pending. Please update the KYC to access all features.</h2></div>
           )}
        </div>
      </div>
    </div>
  );
}

export default Kyc;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { logout } from '../slices/authSlice';
import { fetchTopics } from '../slices/studentSlice';

const CourseDetails = () => {
  const [topics, setTopics] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    const courseId = sessionStorage.getItem('selectedCourseId');
    if (!courseId) {
      navigate('/'); // Redirect if course ID is not found in sessionStorage
      return;
    }

    dispatch(fetchTopics(courseId))
      .unwrap()
      .then(topics => {
        setTopics(topics);
      })
      .catch(error => {
        console.error('Failed to fetch topics:', error);
      });
  }, [dispatch, navigate]);

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      localStorage.removeItem('token');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div className="page-wrapper bg-gra-03 p-t-45 p-b-50">
      <div className="card">
        <div className="card-header">
          <div className='col-md-12 text-right'>
            <b>Welcome to Student's Dashboard</b>
            | <button onClick={handleLogout}><b>Logout <i className="fa fa-sign-out" aria-hidden="true"></i></b></button>
          </div>
        </div>
        <div className="card-body">
          <h2>Topics</h2>
          {topics.length > 0 ? (
            <ul className='dasboard_course_list' style={{listStyle:'none'}}>
              {topics.map(mainTopic => (
                <li key={mainTopic.id} style={{paddingTop:'10px', 
                borderBottom:'2px dotted gray',
                backgroundColor:'#63daff',marginBottom:'15px',
                padding:'15px'}}>

                 <b style={{color:'darkblue'}}>
                  <i className="fa fa-duotone fa-check"></i>
                  {mainTopic.course_topics}
               </b>
                  <ul>
                    {mainTopic.child_topics.map(childTopic => (
                      <li key={childTopic.id}>--{childTopic.topic_name}
                      <br />
                      <b>Details:</b> {childTopic.topic_details}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          ) : (
            <p>Loading topics...</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default CourseDetails;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const mainURL="https://www.acesoftech.com/addmission/backend";
//const mainURL="http://127.0.0.1:8000";

export const fetchCountries = createAsyncThunk(
  'studentForm/fetchCountries',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(mainURL+'/api/countries');
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Something went wrong');
    }
  }
);

export const fetchTopics = createAsyncThunk(
  'studentForm/fetchTopics',
  async (id, thunkAPI) => { // Accept id as parameter
    try {
      const response = await axios.get(mainURL+`/api/admin/course_topics/getCourseTopicsAPI/${id}`);
      console.log('DATASSS', response.data); // Log the data here
      
      return response.data.topics; // Assuming your API response has a 'topics' key
    } catch (error) {
      throw new Error(error.response.data.message || 'Something went wrong');
    }
  }
);

export const fetchInterviewList = createAsyncThunk(
  'studentForm/fetchInterviewList',
  async ({ course_id, topic_id }, thunkAPI) => {

    console.log('course_id',course_id);

    try {
      const response = await axios.get(`${mainURL}/api/admin/interview_list/getInterviewList/${course_id}/${topic_id}`);
      return response.data.interviewList;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Something went wrong');
    }
  }
);


export const fetchMCQList = createAsyncThunk(
  'studentForm/fetchMCQList',
  async ({ course_id, topic_id }, thunkAPI) => {
    console.log('course_id', course_id);

    try {
      const response = await axios.get(`${mainURL}/api/admin/mcq_list/MCQList/${course_id}/${topic_id}`);
      
      console.log('response.data.MCQList',response.data.MCQList);
      return response.data;


    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Something went wrong');
    }
  }
);



export const fetchStates = createAsyncThunk(
  'studentForm/fetchStates',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(mainURL+'/api/state_lists');
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Something went wrong');
    }
  }
);

export const fetchCourseList = createAsyncThunk(
  'studentForm/fetchCourseList',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(mainURL+'/api/courses');
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Something went wrong');
    }
  }
);

export const submitForm = createAsyncThunk(
  'studentForm/submitForm',
  async (formData, thunkAPI) => {
    try {
      const response = await axios.post(mainURL+'/api/students', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Something went wrong');
    }
  }
);

export const studentFormSlice = createSlice({
  name: 'studentForm',
  initialState: {
    formData: {},
    courseList: [],
    countries: [],
    states: [],
    error: null,
    status: 'idle',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.status = 'idle';
        state.countries = action.payload;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.error.message;
      })
      .addCase(fetchTopics.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchTopics.fulfilled, (state, action) => {
        state.status = 'idle';
        state.topics = action.payload;

        console.log( state.topics);
      })
      .addCase(fetchTopics.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.error.message;
      })
      .addCase(fetchStates.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.status = 'idle';
        state.states = action.payload;
      })
      .addCase(fetchStates.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.error.message;
      })
      .addCase(fetchCourseList.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchCourseList.fulfilled, (state, action) => {
        state.status = 'idle';
        state.courseList = action.payload;
      })
      .addCase(fetchCourseList.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.error.message;
      })
      .addCase(submitForm.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(submitForm.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.formData = action.payload;
      })
      .addCase(submitForm.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.error.message;
      });
  },
});

export default studentFormSlice.reducer;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate,Link } from 'react-router-dom';
import { login } from '../slices/authSlice';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [messages, setMessages] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // Dispatch login action with email and password
      const result = await dispatch(login({ credentials: { email, password } }));
      // If login is successful, navigate to student-dashboard
      if (result.payload && result.payload.id) {
        navigate('/student-dashboard');
      }
      else{
        setMessages('Invalid email or password');
      }
    } catch (error) {
      // Set error message if login fails
      setMessages('Invalid email or password');
    }
  };

  return (
    <div className="page-wrapper bg-gra-03 p-t-65 p-b-50"
    style={{ width: '', display: 'flex', justifyContent: 'center',
     alignItems: 'center', margin: '0 auto' }}>

        <div className="card" style={{ width: '400px' }}>
        {messages ? (
          <div className="card-header error" style={{backgroundColor:'darkblue'}}><h3>{messages}</h3></div>
        ) : (
          <div className="card-header text-center" style={{backgroundColor:'darkblue',color:'white'}}><h3>
          
          <i class="fa fa-graduation-cap"></i>&nbsp;Student's Login</h3></div>
        )}
        <div className="card-body">
          <div className="form-group custom-form-group">
            <input type="email" className="form-control" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className="form-group custom-form-group">
            <input type="password" className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
          </div>

          <div className='col-md-12 text-center custom-form-group'>
             <Link to='/forgot-password'>Forgot Passowrd?</Link>
          </div>

          <div className='col-md-12 text-center custom-form-group'>
          <button className="btn btn-success btn-block login-btn" onClick={handleLogin} style={{backgroundColor:'darkblue'}}>Please Login</button>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default Login;

// store.js
import { configureStore } from '@reduxjs/toolkit';
import studentFormSlice from './slices/studentSlice';
import authReducer from './slices/authSlice';
import globalReducer from './slices/globalSlice';
export const store = configureStore({
  reducer: {
    studentForm: studentFormSlice,
    auth: authReducer,
    globals:globalReducer
  },
});

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom'; 
import { logout, fetchCourseDetails } from '../slices/authSlice';

const Dashboard = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [courses, setCourses] = useState([]);
  const [studentId, setStudentId] = useState('');
  const [status, setStatus] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  useEffect(() => {
    const token = localStorage.getItem('token');

    dispatch(fetchCourseDetails())
      .unwrap()
      .then(courseDetails => {
        setCourses(courseDetails); 
      })
      .catch(error => {
        console.error('Failed to fetch course details:', error);
      });

    if (token) {
      try {
        const tokenObject = JSON.parse(token);
        setFirstName(tokenObject.first_name);
        setLastName(tokenObject.last_name);
        setStatus(tokenObject.status);
        setStudentId(tokenObject.id);
      } catch (error) {
        console.error('Error parsing token:', error);
      }
    }
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      localStorage.removeItem('token');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleCourseButtonClick = (courseId) => {
    sessionStorage.setItem('selectedCourseId', courseId);
    navigate(`/course-details`);
  };


  const handleInterviewButtonClick = (courseId) => {
    sessionStorage.setItem('selectedCourseId', courseId);
    navigate(`/interview-q-n-a`);
  };


  const handleMCQ = (courseId) => {
    sessionStorage.setItem('selectedCourseId', courseId);
    navigate(`/mcq-list`);
  };


  return (
    <div className="page-wrapper bg-gra-03 p-t-45 p-b-50">
      <div className="card">
        <div className="card-header">
          <div className='col-md-12 text-right'>
            <b>Welcome to Student's Dashboard</b>
            | <button onClick={handleLogout}><b>Logout <i className="fa fa-sign-out" aria-hidden="true"></i></b></button>
          </div>
        </div>
        <div className="card-body">
         
          <ul className='kyc text-center'>
            <li><Link to="/student-details"><b>
            <i class="fa fa-user" aria-hidden="true"></i>
            &nbsp;View Personal Your Details</b></Link></li>
            <li>
              <Link to="/kyc-details">
              <i class="fa fa-edit"></i>
              &nbsp; View KYC Details
               </Link>
            </li>
            <li><Link to="/resume-building">&nbsp;
               <i class="fa fa-file" aria-hidden="true"></i>
               &nbsp; Resume Building</Link></li>
            <li><Link to="/resume-building">&nbsp;
            <i class="fa fa-question-circle"></i>
            &nbsp;Mock Interview</Link></li>
            <li>
            <Link to="/interview-q-n-a">&nbsp;
            <i class="fa fa-question" aria-hidden="true"></i>
            &nbsp;Interview Questions & Answers</Link>
            </li>
          </ul>
       
          {status === 0 ? (
            <p>
              Your current status is inactive. Please contact admin to make it active.
              Once activated, you will be able to access full features such as course materials,
              your fees details, etc.
            </p>
          ) : (
            <>
              <div className='enrolled_list text-left'>
                <h3>You have enrolled for the below course(s)</h3>
                <ul>
                  {courses.map((course, index) => (
                    <li key={index}>
                    
                    <span style={{
                      padding:'15px',
                      marginLeft:'30px',
                      paddingTop:'0px',
                      paddingBottom:'40px',
                     
                      }}>
                      <button onClick={() => handleCourseButtonClick(course.course_id)}> - <span style={{color:'black'}}><h4>  <i class="fa fa-search"></i> &nbsp;Course Materials - {course.course_name}</h4></span></button>
                    
                     </span>
                     
                    
                     <span style={{
                     
                      marginLeft:'30px',
                      paddingTop:'0px',
                      paddingBottom:'40px',
                     
                      }}><button onClick={() => handleInterviewButtonClick(course.course_id)}> - <h4> <i class="fa fa-handshake-o"></i> &nbsp; <span style={{color:'black'}} >Interview Q.A.</span> </h4></button></span> 
                      
                      {/* <Link to={`/course-details/${encodeURI(course.course_id.toString())}`}>{course.course_name}</Link> */}
                   
                   
                      <span style={{padding:'15px',
                      marginLeft:'30px',
                      paddingTop:'0px',
                      paddingBottom:'40px',
                     
                      }}><button onClick={() => handleMCQ(course.course_id)}> - <h4> <i class="fa fa-handshake-o"></i> &nbsp; <span style={{color:'black'}} >MCQ TEST</span> </h4></button></span> 
                    

                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

// globalSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  imagePath: 'https://www.acesoftech.com/addmission/backend/student_photos/', // Initial value for the image path
};

const globalSlice = createSlice({
  name: 'globals',
  initialState,
  reducers: {
    // No need for setImagePath action, directly set the imagePath
  },
});

export const selectImagePath = (state) => state.globals.imagePath;

export default globalSlice.reducer;

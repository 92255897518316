import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ element, ...rest }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const tokenString = localStorage.getItem('token');
    if (!tokenString) {
      navigate('/login'); // Redirect to login page if token is not available
    }
  }, [navigate]);

  // If authenticated, render the specified element
  return <>{element}</>;
};

export default ProtectedRoute;

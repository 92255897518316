import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom'; 
import { logout, fetchCourseDetails } from '../slices/authSlice';

const ResumeBuilding = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [courses, setCourses] = useState([]);
  const [studentId, setStudentId] = useState('');
  const [status, setStatus] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  useEffect(() => {
    const token = localStorage.getItem('token');

    dispatch(fetchCourseDetails())
      .unwrap()
      .then(courseDetails => {
        setCourses(courseDetails); 
      })
      .catch(error => {
        console.error('Failed to fetch course details:', error);
      });

    if (token) {
      try {
        const tokenObject = JSON.parse(token);
        setFirstName(tokenObject.first_name);
        setLastName(tokenObject.last_name);
        setStatus(tokenObject.status);
        setStudentId(tokenObject.id);
      } catch (error) {
        console.error('Error parsing token:', error);
      }
    }
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      localStorage.removeItem('token');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };


  //console.log('courses',courses);

  return (
 
    <div className="page-wrapper bg-gra-03 p-t-45 p-b-50">
      <div className="card">
        <div className="card-header">
          <div className='col-md-12 text-right'>
            <b>Welcome to Student's Dashboard</b>
            | <button onClick={handleLogout}><b>Logout <i className="fa fa-sign-out" aria-hidden="true"></i></b></button>
          </div>
        </div>
        <div className="card-body">
            <a href='https://docs.google.com/document/d/1HXDW41tqikVmSl36Jle025rmUdNyztEib28QqPiVk14/edit?usp=sharing' target='blank'><h2>Download Sample Resume</h2></a>
            <ul className='resume_building'>
                <li>
                <i className="fa fa-duotone fa-check"></i> <b>Include a Photo in Your Resume:</b> You should include a photo in your resume.</li>
                <li>
                <i className="fa fa-duotone fa-check"></i>  <b>Include Live URLs of Projects:</b> If you include live URLs of the projects in your resume, the hiring chances increase by 200%.</li>
                <li>
                <i className="fa fa-duotone fa-check"></i>  <b>Admit When You Don't Know:</b> If you do not know the answer to any questions, politely say, "I don't know." Don't try to fool the interviewer.
                </li>
                <li>
                <i className="fa fa-duotone fa-check"></i>  <b>Dress Formally:</b> Always wear formal dress. It's saying, "Dress is the non-verbal expression of one's personality."
                </li>

                <li>
                <i className="fa fa-duotone fa-check"></i>  <b>Send a Thank You Email:</b> Once your interview is finished, send a thank you email saying it was a nice interview interaction and expecting a positive response from the company.
                </li>

                <li>
                <i className="fa fa-duotone fa-check"></i> <b>Maintain Calm Posture:</b> Don't make unnecessary chair movements. Maintain a calm posture during the interview.
                </li>

            </ul>
        </div>
      </div>
    </div>
  );
}

export default ResumeBuilding;

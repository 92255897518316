import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation,useParams  } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './App.css';
import Header from './components/common/Header';
import RegistrationForm from './components/RegistrationForm';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import ProtectedRoute from './components/protectedRoutes';
import StudentDetails from './components/StudentDetails';
import CourseDetails from './components/CourseDetails';
import Kyc from './components/kyc';
import ForgotPassword from './components/forgotPassword';
import Footer from './components/common/Footer';
import ResumeBuilding from './components/Resumebuilding';
import InterviewQA from './components/InterviewQA';
import InterviewDetails from './components/InterviewDetails';
import MCQtest from './components/MCQTest';
import MCQDetails from './components/MCQDetails';

// Header component with condition to hide
const HeaderWithCondition = () => {




  const location = useLocation();

  // Function to determine whether to show the header
  const showHeader = () => {
    return !location.pathname.includes('/login'); // Hide header on login page
  };

  return showHeader() ? <Header /> : null;
};

function App() {
  return (
    <Router>
      <div className="App">
        <HeaderWithCondition />
        <div className="container">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/registration" element={<RegistrationForm />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/student-dashboard/*" element={<ProtectedRoute element={<Dashboard />} />} />
            <Route path="/student-details/*" element={<ProtectedRoute element={<StudentDetails />} />} />
            <Route path="/course-details" element={<ProtectedRoute element={<CourseDetails />} />} />
            <Route path="/kyc-details" element={<ProtectedRoute element={<Kyc />} />} />
            <Route path="/resume-building" element={<ProtectedRoute element={<ResumeBuilding />} />} />
            <Route path="/interview-q-n-a" element={<ProtectedRoute element={<InterviewQA />} />} />
            <Route path="/interview-details" element={<ProtectedRoute element={<InterviewDetails />} />} />
            <Route path="/mcq-list" element={<ProtectedRoute element={<MCQtest />} />} />
            <Route path="/mcq-details" element={<ProtectedRoute element={<MCQDetails />} />} />
          </Routes>
        </div>
        <Footer/>
      </div>
    </Router>
  );
}

export default App; 
